import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import JobList from '../components/JobList';

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const jobs = data.jobs.edges;

  return (
    <Layout location={location} title={siteTitle}>
      <h1 className="display-1">foriio Jobs</h1>
      <p className="lead text-muted">
        クリエイターのプラットフォーム直結の求人情報掲示板、foriio Jobs。
      </p>
      <SEO title="All jobs" />
      <JobList jobs={jobs} />
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query Index($currentDate: Date!) {
    site {
      siteMetadata {
        title
      }
    }
    jobs: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/companies/**/jobs/**.md" }
        frontmatter: { expiryDate: { gte: $currentDate } }
      }
      sort: { fields: [frontmatter___createdDate], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            jobId
            title
            description
            tags
            company {
              fields {
                slug
              }
              frontmatter {
                name
                website
                location
                logo {
                  childImageSharp {
                    fluid(maxWidth: 50) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
